.currency-component select {
  /* padding: 12px;
  border-radius: 6px; */
  background-color: transparent;
  border: none;
  outline: none;
}

.currency-component {
  border: 1px solid #8692a6;
  display: grid;
  grid-template-columns: 30% 65%;
  height: 42px;
  width: 187px;
  margin-left: 390px;
  /* top: 1095px; */
  border-radius: 6px;
}

.currency-component img {
  height: 15px;
  width: 22px;
  margin-left: 13px;
  margin-top: 12px;
  border-radius: 0px;
}

@media (max-width: 912px) {
  .currency-component {
    margin-left: 50% !important;
    width: 177px;
    margin-top: 0%;
  }

  /* .currency-component img {
    height: 15px;
    width: 15px;
    margin-left: 13px;
    margin-top: 12px;
    border-radius: 0px;
  } */
}

@media (max-width: 540px) {
  .currency-component {
    margin-left: 65% !important;
    width: 117px;
    margin-top: -14%;
    margin-left: 130px;
  }
}
