header {
  border-right: 1px solid #d9dbe9;
  padding-top: 30px;
  padding-right: 83px;
  margin-left: 0 !important;
}

.logo {
  padding-left: 54px;
}

.nav-link {
}

.nav-link-item {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 1px;

  margin-top: 30%;
  text-decoration: none;
  list-style: none;
  margin-left: -50%;

  padding-left: 54px;
  display: grid;
  grid-template-columns: 5% 15% 55%;
  grid-gap: 15%;
}

.active-nav-link-item .nav-icon {
  background-color: #1d9bf0;
  border-radius: 5px;
}

.active-nav-link-item .nav-icon img {
  margin-left: 25%;
}

.nav-link-item a {
  list-style: none;
  text-decoration: none;
  color: #0000006e;
}

.active-nav-link-item a {
  color: #14142b;
  text-decoration: none;
}

.stripe {
  border-left: 4px solid #1d9bf0;
  height: 30px;
  width: 7px;
  margin-left: -10px;
  margin-top: 1px;
  border-radius: 5px;
}

@media (max-width: 912px) {
  .stripe {
    border-left: 4px solid #1d9bf0;
    height: 30px;
    width: 7px;
    margin-left: 10px;
    margin-top: 1px;
    border-radius: 5px;
  }

  header {
    padding-top: 30px;
    padding-right: 0px !important;
    margin-left: 0 !important;
  }
  .nav-link-lists {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    padding-left: 0px;
  }

  .active-nav-link-item .nav-icon {
    padding: 4px !important;
  }

  .home-nav {
    /* background-color: pink; */
  }

  .fav-nav {
    /* background-color: blue; */
  }

  .nav-link-item {
    font-size: 24px;
    margin-left: 3% !important;
    display: grid;
    grid-template-columns: 3% 20% 75%;
    grid-gap: 2%;
  }

  .active-nav-link-item .nav-icon img {
    margin-left: 0%;
  }
}

@media (max-width: 540px) {
  header {
    border-right: none;
    border-bottom: 1px solid #d9dbe9;
    padding-top: 30px;
    padding-right: 183px;
    margin-left: 0 !important;
    /* background-color: red; */
  }

  .nav-link-lists {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .nav-link-item {
    /* border: 1px solid red; */
    font-size: 18px;
    margin-left: 143px;
  }

  .nav-link-item {
    font-family: Poppins;
    /* font-size: 20px; */
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 1px;

    margin-top: 30%;
    text-decoration: none;
    list-style: none;
    /* margin-left: -50%; */

    padding-left: 0px;
  }
}
