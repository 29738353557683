.home {
  height: 100%;
  padding-left: 45px;
  padding-top: 114px;
}

.home h1 {
  padding-bottom: 72px;
}

@media (max-width: 912px) {
  .home {
    height: auto;
    padding-left: 5px;
    padding-top: 94px;
  }
}
