.favorite {
  height: 100%;
  padding-left: 45px;
  padding-top: 114px;
}

.favorite-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2%;
}

.favorite h1 {
  padding-bottom: 72px;
}

@media (max-width: 912px) {
  .favorite {
    height: 100%;
    padding-left: 5px;
    padding-top: 114px;
  }
  .favorite-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3%;
  }
}

@media (max-width: 540px) {
  .favorite-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 4%;
  }
}
