footer {
  display: grid;
  grid-template-columns: 50% 50%;
  padding-left: 50px;
  padding-right: 94px;
  padding-bottom: 1%;
}

.currency {
  text-align: right;
}

@media (max-width: 912px) {
  footer {
    padding: 4px !important;
    margin-top: 30%;
    padding-left: 20px !important;
  }
}

@media (max-width: 540px) {
  footer {
    grid-template-columns: 100%;
    padding: 4px !important;
    margin-top: 130%;
    padding-left: 20px !important;
  }

  .currency {
    text-align: left !important;
  }
}
