.card {
  /* height: 224px; */
  width: 238px;
  left: 345px;
  top: 220px;
  border-radius: 10px;
  background-color: #fff;
}

.card-top {
  position: relative;
}

.fav-icon {
  position: absolute;
  height: 35px;
  width: 35px;
  left: 190px;
  top: 14px;
  border-radius: 10px;

  border-radius: 10px;
}

.faved-icon {
  background: #fff;
}

.unfaved-icon {
  background: #bbbbbb;
}

.fav-icon img {
  margin-left: 10px;
  margin-top: 10px;
}

.card-mid-cover {
  border-radius: 10px;
  height: 139px;
  width: 228px;
  overflow: hidden;
  margin-left: 5px;
  margin-top: 5px;
  border-radius: 10px;
}

.card-top .cover {
  width: 228px !important;
  border-radius: 10px;
}

.card-mid-image {
  position: relative;
  height: 44px;
  width: 44px;
  margin-left: 13px;
  margin-top: -28px;
  border-radius: 40px;
  overflow: hidden;
  border: 2px solid #fff;
}

.card-mid-image img {
  width: 44px;
}

.card-lower {
  display: grid;
  grid-template-columns: 50% 50%;
  padding-left: 12px;
  padding-right: 13px;
}

.card-lower-right {
  text-align: right;
  margin-top: 15px;
  font-family: Public Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  color: #1d9bf0;
}

.card-dev-name {
  font-family: Public Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: -8%;
}

.card-dev-price {
  font-family: Public Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 0, 0, 0.49);
}

@media (max-width: 540px) {
  .card {
    /* height: 224px; */
    width: 238px;
    margin-left: 65px;
    top: 220px;
    border-radius: 10px;
    background-color: #fff;
  }
}
