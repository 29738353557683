.app {
  height: auto;
}

.app_division {
  display: grid;
  grid-template-columns: 20% 70%;
  margin-bottom: 175px;
}

@media (max-width: 912px) {
  .app {
    height: inherit !important;
  }
  .app_division {
    grid-template-columns: 100%;
    margin-bottom: 15px;
  }
}

@media (max-width: 540px) {
  .app_division {
    grid-template-columns: 100%;
    margin-bottom: 15px;
  }
}
