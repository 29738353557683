.developer-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 2%;
}

@media (max-width: 912px) {
  .developer-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2%;
  }
}

@media (max-width: 540px) {
  .developer-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0.5%;
  }
}
